.Button {
	box-shadow: 0px 0px 0px transparent;
	border: 0px solid transparent;
	text-shadow: 0px 0px 0px transparent;

	border-radius: 4px;
	background-color: #000;
	color: #fff;
	font-weight: bold;
	text-align: center;
	display: block;
	width: 100%;
	padding: 16px;
	cursor: pointer;
	font-family: "Avenir";
	font-size: 16px;
	margin-top: 32px;
}
