@font-face {
	font-family: "Avenir";
	src: url("assets/fonts/Avenir-Book.woff2") format("woff2"),
		url("assets/fonts/Avenir-Book.woff") format("woff");
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Avenir";
	src: url("assets/fonts/Avenir-Heavy.woff2") format("woff2"),
		url("assets/fonts/Avenir-Heavy.woff") format("woff");
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

body {
	background-color: #f4f6fa;
	font-family: "Avenir";
	font-size: 16px;
}

h1 {
	font-weight: bold;
	font-size: 23px;
	line-height: 28px;
	margin-bottom: 24px;
}

p {
	margin-top: 0;
	margin-bottom: 24px;
}
