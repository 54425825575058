.Input {
	border: none;
	background-image: none;
	background-color: transparent;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	box-sizing: border-box;

	display: block;
	margin-bottom: 16px;
	width: 100%;
	border-radius: 4px;
	border: 1px solid #7c7e81;
	padding: 12px 8px;
	background-color: #fff;
}

.Label {
	display: block;
	margin-bottom: 4px;
}
