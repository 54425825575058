.Header {
	background-color: #ffffff;
	box-shadow: 2px 2px 7px 0 rgba(0, 0, 0, 0.05);
	text-align: center;
	margin-bottom: 32px;

	.Logo {
		height: 30px;
		padding-top: 16px;
		padding-bottom: 16px;
		vertical-align: middle;
	}
}
